export default {
  gift: {
    title: 'Blue Apron Gift Cards - Meal Delivery & Wine Gifts - Blue Apron',
    description:
      'Discover Blue Apron gift cards for food & wine delivery. Learn more about our wine or meal delivery gifts & send a digital gift card today!'
  },
  pricing: {
    title: '12 meals on us when you join today!',
    description: `Explore affordable meal delivery at its finest with Blue Apron – featuring fresh, high-quality ingredients in every meal kit. Elevate your dining experience with our renowned Blue Apron recipes, where affordability meets gourmet dining`
  },
  on_the_menu: {
    title: 'Subscribe today and receive 12 meals for free!',
    description: `Enjoy a world of fresh and affordable culinary experiences with Blue Apron's meal kits. Savor the convenience of expertly curated meals and high-quality ingredients delivered to your door. Dive into a delicious journey with our exclusive Blue Apron recipes.`,
    thumbnail_image: 'https://media.blueapron.com/assets/registration/on-the-menu/countertop_display.jpg?height=750',
    thumbnail_image_height: '750'
  },
  competitor_comparison: {
    title: 'What Makes Our Meals Kits Stand Out | Blue Apron',
    description:
      'Find out what separates Blue Apron from Hello Fresh, Home Chef, Cook Unity, and others. Fresh, quality ingredients at an affordable price.'
  },
  gifts: {
    title: 'Blue Apron Gift Cards - Gifts & Gift Cards - Blue Apron',
    description:
      'Discover Blue Apron gift cards for food & wine delivery. Learn more about our meal delivery gifts & send a digital gift card today!'
  },
  market_checkout: {
    title: 'Meal-Kit Plans & Pricing | Food Delivery Service | Blue Apron',
    description:
      'Blue Apron cost per meal varies based on the serving size and meal kit you choose. Learn more about how to make the most of your Blue Apron subscription.'
  },
  registrations: {
    title: 'Sign-Up',
    description:
      'Looking to create a Blue Apron account? Your journey towards a seamless cooking experience starts here. Create an account today & get cooking!'
  },
  home: {
    title: 'Get 12 meals free if you sign up today!',
    description: `Discover affordable culinary delights with Blue Apron's meal kits and prepared & ready meals– now featuring the same fresh ingredients you love. Elevate your dining experience with our chef curated recipes, crafted with high-quality ingredients.`
  },
  wine: {
    title: 'Wine Delivery - Gifts & Subscriptions',
    description:
      'Explore wine delivery options from Blue Apron. Get access to delicious wines from renowned winemakers. Learn more about our wine delivery & subscriptions!'
  },
  marketplace: {
    title: 'Blue Apron Market - Wine, Spices, Cookware, Kitchen Tools',
    description:
      'Discover the Blue Apron Market for spices, wine, cookware, kitchen utensils, tools & more. Find everything you need for cooking & hosting in our store.'
  },
  show_recipes: {
    title: 'Weekly Meal Delivery Menus',
    description:
      'Choose from a variety of meat, fish, vegetarian, WW Recommended, Wellness, health-conscious recipes & more. View our weekly dinner menu & meal kit recipes.'
  },
  sessions: {
    title: 'Account Log-in',
    description:
      "Login to your Blue Apron account. Don't have an account login? Sign up for a Blue Apron account & discover the benefits of meal delivery today!"
  }
};
